<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      width="550px"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <v-system-bar height="47" color="#aeddff'">
            <h3 class="black--text">
              {{ title }}
            </h3>
          </v-system-bar>
          <v-divider />
          <v-card-text>
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <h4>{{ text }}</h4>
              </div>
            </div>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="120px"
              label-position="top"
              v-if="requireInput"
              class="demo-ruleForm"
            >
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <el-form-item :label="inputHint" prop="input">
                    <el-input size="mini" v-model="form.input"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
            <v-divider class="mt-5" />
            <div class="row mt-2">
              <div class="col-lg-12 col-sm-12">
                <v-btn
                  title
                  color="warning"
                  @click="cancel"
                  elevation="0"
                  class="mr-1"
                >
                  <v-icon color="white">mdi-close-circle</v-icon>
                  Cancel
                </v-btn>
                <v-btn title color="success" @click="confirm" elevation="0">
                  <v-icon color="white">mdi-check</v-icon>
                  Confirm
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import { Button, Form, FormItem, Input } from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
export default {
  created() {},
  watch: {
    show: function () {
      this.showForm = this.show;
    },
  },
  props: {
    title: {
      type: String,
      default: "Confirm",
    },
    text: {
      type: String,
      default: "Confirm",
    },
    show: {
      type: Boolean,
      default: false,
    },
    requireInput: {
      type: Boolean,
      default: false,
    },
    inputHint: {
      type: String,
      default: "Enter Details",
    },
  },
  newd() {},
  components: {},
  data() {
    return {
      form: {
        input: "",
      },
      rules: {
        input: [
          {
            required: true,
            message: "Please Enter " + this.inputHint,
            trigger: "blur",
          },
        ],
      },
      permissions: [],
      loading: false,
      showForm: false,
    };
  },
  methods: {
    confirm() {
      if (this.requireInput) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.$emit("confirm", this.form.input);
          } else {
            return false;
          }
        });
      } else {
        this.$emit("confirm", this.form.input);
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  computed: {},
  name: "ConfirmForm",
};
</script>
<style scoped>
.v-dialog {
  overflow-y: none !important;
}
</style>
