import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

import "element-ui/lib/theme-chalk/index.css";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
// configure language
locale.use(lang);

import "./assets/css/components.css";
import "./assets/css/fonts.css";
import "./assets/css/dhivehi.css";

import functions from "./plugins/functions";

new Vue({
  router,
  vuetify,
  functions,
  render: (h) => h(App),
}).$mount("#app");
