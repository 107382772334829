import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "home" */ "../components/events/index.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../components/events/index.vue"
      ),
  },
  {
    path: "/email/verify/:id/:param",
    name: "VerifyUserEmail",
    component: () =>
      import(
        /* webpackChunkName: "verify-user-email" */ "../components/users/verifyEmail.vue"
      ),
  },
  {
    path: "/events",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "events" */ "../components/events/index.vue"),
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../components/users/index.vue"),
  },
  {
    path: "/exhibitors",
    name: "Exhibitors",
    component: () =>
      import(
        /* webpackChunkName: "exhibitors" */ "../components/exhibitor/index.vue"
      ),
  },
  {
    path: "/exhibitor/event/view/:id",
    name: "ViewExhibitorEvent",
    component: () =>
      import(
        /* webpackChunkName: "exhibitor" */ "../components/exhibitor/eventProfie.vue"
      ),
  },
  {
    path: "/exhibitor",
    name: "ViewExhibitor",
    component: () =>
      import(
        /* webpackChunkName: "exhibitor" */ "../components/exhibitor/my.vue"
      ),
  },
  {
    path: "/visitors",
    name: "VisitorList",
    component: () =>
      import(
        /* webpackChunkName: "visitors" */ "../components/visitors/index.vue"
      ),
  },
  {
    path: "/locations",
    name: "LocationsList",
    component: () =>
      import(
        /* webpackChunkName: "locations" */ "../components/locations/index.vue"
      ),
  },
  {
    path: "/visitor-types",
    name: "VisitorTypesList",
    component: () =>
      import(
        /* webpackChunkName: "visitor-types" */ "../components/visitortypes/index.vue"
      ),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../components/auth/forgotPassword.vue"
      ),
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "sign-in" */ "../components/auth/SignIn"),
  },
  {
    path: "/sign-out",
    name: "SignOut",
    component: () =>
      import(/* webpackChunkName: "sign-in" */ "../components/auth/SignOut"),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ "../components/auth/changepassword"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
