import Vue from "vue";
import Swal from "sweetalert2";
import moment from "moment";
import Repo, { baseUrl, imageUrl } from "../utils/repo";
import Confirm from "@/components/custom/confirm.vue";
export default Vue.mixin({
  components: {
    Confirm,
  },
  data() {
    return {
      dialogImageUrl: null,
      dialogVisible: false,
      dialogPdf: null,
      dialogPdfVisible: false,
      app_title: "Events.mv",
      no_img: require("@/assets/images/no-image.png"),
      no_img_sq: require("@/assets/images/no-img-sq.jpg"),
      userMenus: [
        { id: "0", text: "Events", link: "/events" },
        { id: "1", text: "Exhibitors", link: "/exhibitors" },
        { id: "2", text: "Visitors", link: "/visitors" },
        { id: "3", text: "Visitor Types", link: "/visitor-types" },
        { id: "4", text: "Locations", link: "/locations" },
        { id: "5", text: "Users", link: "/users" },
      ],
      rateTypes: [
        { id: "Percentage", rate_type: "Percentage" },
        { id: "Amount", rate_type: "Amount" },
      ],
      frequencys: [
        { id: "Pay Cycle", frequency: "Pay Cycle" },
        { id: "Annual", frequency: "Anual" },
        { id: "AdHock", frequency: "AdHock" },
      ],
      smsTypes: [
        { id: "Rest Api", name: "Rest Api" },
        { id: "oAuth2", name: "oAuth2" },
        { id: "Authorization Key", name: "Authorization Key" },
      ],
      sumFieldTypes: [
        { id: "tinyint", field_type: "tinyint" },
        { id: "smallint", field_type: "smallint" },
        { id: "int", field_type: "int" },
        { id: "money", field_type: "money" },
        { id: "float", field_type: "float" },
        { id: "decimal", field_type: "decimal" },
        { id: "numeric", field_type: "numeric" },
        { id: "smallmoney", field_type: "smallmoney" },
        { id: "bigint", field_type: "bigint" },
      ],
      fileTypes: [
        "image/jpeg",
        'file.type === "image/png',
        'file.type === "image/bmp',
        "application/pdf",
        "form.vue?aefa:506 application/x-zip-compressed",
        "form.vue?aefa:506 application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "form.vue?aefa:506 application/vnd.ms-excel",
        "form.vue?aefa:506 application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "form.vue?aefa:506 application/msword",
        "form.vue?aefa:506 text/plain",
      ],
      selectedMenu: null,
      futurePickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now();
        },
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      pastPickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now();
        },
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      datePickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  computed: {
    user() {
      return !this.empty(localStorage.getItem("er_client_user"))
        ? JSON.parse(localStorage.getItem("er_client_user"))
        : "";
    },
    token() {
      return localStorage.getItem("er_client_token");
    },
    imUrl() {
      return `${imageUrl}`;
    },
    apiUrl() {
      return `${baseUrl}`;
    },
  },
  methods: {
    signOut() {
      this.loading = false;
      localStorage.setItem("er_client_user", "");
      localStorage.setItem("er_client_token", "");
      document.location.href = "/";
      this.api("auth/logout")
        .then(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    empty(text) {
      if (text == "" || text == undefined) {
        return true;
      } else {
        return false;
      }
    },
    datePassed(date) {
      var now = this.formatDate(new Date());
      if (date < now) {
        return false;
      } else {
        return false;
      }
    },
    async api(api, post_data, apiUrl = `${baseUrl}`) {
      let self = this;
      var promise = await new Promise(function (resolve, reject) {
        Repo.post(apiUrl + api, post_data)
          .then((response) => {
            resolve(response.data);
            return response.data;
          })
          .catch(function (error) {
            if (self.empty(error.response)) {
              Swal.fire({
                title: "Error",
                text: "Server could not be found",
                type: "question",
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonColor: "#5daf34",
                cancelButtonColor: "#d33",
                showCancelButton: true,
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.value) {
                  localStorage.setItem("er_client_user", "");
                  localStorage.setItem("er_client_token", "");
                  self.$router.push("/sign-in");
                }
              });
              return;
            } else if (error.response.status == 401) {
              localStorage.setItem("er_client_user", "");
              localStorage.setItem("er_client_token", "");
              console.log("1z-3");
              if (self.$router.currentRoute.path != "/sign-in") {
                self.$router.push("/sign-in");
              }
              reject("Authenthication Failed");
              return "Authenthication Failed";
            } else if (
              error.response.status == 401 &&
              self.$router.currentRoute.path != "/sign-in"
            ) {
              self.$router.push("/sign-in");
              return;
            } else if (error.response.status == 500) {
              console.log(error.response);
              if (
                !self.empty(error.response.data.error) &&
                error.response.data.error.includes("duplicate key ") &&
                error.response.data.error.includes("unique")
              ) {
                error.response.data.error = "Duplicate Entry";
                self.handleErrors(error);
                reject(error);
                return error;
              } else if (
                !self.empty(error.response.data.exception) &&
                error.response.data.exception.includes("QueryException")
              ) {
                error =
                  "DB Level Error Occured, Please contact System Administration";
                self.showError(error);
                reject(error);
                return error;
              } else {
                reject(error);
                return error;
              }
            } else if (error.response.status == 401) {
              self.showError("Email or Password incorrect");
            } else if (error.response.message == "Unauthenticated") {
              self.showError("Unauthenticated");
            } else {
              self.handleErrors(error);
            }
            reject(error);
            return error;
          });
      });
      return promise;
    },
    sinceDate(date) {
      let d = this.formatDateTime(date);
      return moment(d).fromNow();
    },
    normalizeDate(date) {
      return new Date(date.getTime() + date.getTimezoneOffset());
    },
    formatDateTable(_col, _row, value) {
      return this.formatDate(value);
    },
    formatDate(value, format = "YYYY-MM-DD") {
      if (this.empty(value)) return "";
      return moment(value).format(format);
    },
    formatDateTime(value, format = "YYYY-MM-DD HH:mm:ss") {
      //return this.normalizeDate(value);
      if (this.empty(value)) return "";
      return moment(value).format(format);
    },
    formatTime(value, format = "HH:mm:ss") {
      if (this.empty(value)) return "";
      if (value.length < 17) {
        var newDate = new Date();
        value = newDate.toDateString() + " " + value;
      }
      return moment(value).format(format);
    },
    formatNumber(value, decimals = 2) {
      if (this.empty(value)) return "0.00";
      let v = parseFloat(value);
      var parts = v.toFixed(decimals).split(".");
      var num =
        parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
        (parts[1] ? "." + parts[1] : "");
      return num;
    },
    getDatTime(value) {
      if (this.empty(value)) return "";
      var newDate = new Date();
      value = newDate.toDateString() + " " + value;
      return value;
    },
    getDayName(dateStr, locale = "en-US") {
      var date = new Date(dateStr);
      return date.toLocaleDateString(locale, { weekday: "long" });
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    toInt(val) {
      return parseInt(val);
    },
    toTitleCase(str) {
      if (this.empty(str)) return;
      str = str.replaceAll("_", " ");
      str = str.replaceAll("-", " ");
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ");
    },
    async sendSmsMessage(mobile_no, message, target_audiences_id) {
      let f = {
        mobile_no: mobile_no,
        message: message,
        target_audiences_id: target_audiences_id,
      };
      await Repo.post(`${baseUrl}send-sms`, f)
        .then((response) => {
          return response.data;
        })
        .catch(function (error) {
          return error;
        });
    },
    colFormatYesNo(_row, _col, value) {
      return value == "Yes" || value == true || value == 1 ? "Yes" : "No";
    },
    colFormatDateTime(_row, _col, value) {
      return this.formatDateTime(value);
    },
    colFormatDate(_row, _col, value) {
      return this.formatDate(value);
    },
    colFormatTime(_row, _col, value) {
      return this.formatTime(value, "HH:mm");
    },
    colGetTimeOnly(_row, _col, value) {
      if (this.empty(value)) return value;
      if (value.length < 5) return value;
      return value.substring(0, 5);
    },
    colFormatNumber(_row, _col, value) {
      return this.formatNumber(value);
    },
    formatUpperCase(value) {
      return value.toUpperCase();
    },
    filterArrayByValue(array, string) {
      return array.filter((o) =>
        Object.keys(o).some((k) =>
          o[k].toLowerCase().includes(string.toLowerCase())
        )
      );
    },
    filterArrayByValueTitleCase(array, string) {
      return array.filter((o) =>
        Object.keys(o).some((k) =>
          this.toTitleCase(o[k]).toLowerCase().includes(string.toLowerCase())
        )
      );
    },
    arrayGetIndex(array, item) {
      return array.indexOf(item);
    },
    getObjectFromArray(array, key, value) {
      if (array == null || array.length <= 0) return;
      if (this.empty(key) || this.empty(value)) return;
      let self = this;
      return array.filter(function (keyObject) {
        return (
          (!self.empty(keyObject[key]) ? keyObject[key] : "")
            .toString()
            .toLowerCase() == value.toString().toLowerCase()
        );
      });
    },
    displayError(msg) {
      if (msg.response.data.message == "Unauthenticated.") {
        this.$router.push("/signIn");
      } else if (msg.response.status == 422) {
        //Validation Errors
        let er = msg.response.data.error;
        let erMsg = "";
        if (typeof er === "object") {
          /*for (var key in er) {
            if (er.hasOwnProperty(key)) {
              erMsg += er[key][0] + " <br/>";
            }
          }*/
        } else {
          erMsg = er;
        }
        if (!this.empty(erMsg)) {
          erMsg = '<span class="text-danger">' + erMsg + "</span>";
          if (msg.response.data.additional_info)
            erMsg +=
              '<br/><span class="text-warning">' +
              msg.data.additional_info +
              "</span>";
          Swal.fire({
            title: "Warning!",
            html: erMsg,
            type: "warning",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {});
        }
        return;
      }
    },
    makeToast(variant = null, msg) {
      if (!this.empty(msg.response)) {
        this.displayError(msg);
        return;
      }
      this.$bvToast.toast(msg, {
        title: ` ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },
    handleErrors(error) {
      if (!error.response) {
        Swal.fire({
          title: "Error!",
          html: "Could not connect to the server.",
          icon: "error",
          confirmButtonText: "Alright",
        });
        return;
      }
      if (
        error.response.status == 422 &&
        !this.empty(error.response.data.error)
      ) {
        let validation = error.response.data.error;
        let errors = "";
        Object.keys(validation).forEach((key) => {
          let e = validation[key];
          e.forEach((item) => {
            errors += item + "<br/>";
          });
        });
        Swal.fire({
          title: "Validations Failed!",
          html: errors,
          icon: "error",
          confirmButtonText: "Alright",
        });
      } else if (
        error.response.status == 422 &&
        !this.empty(error.response.data.error)
      ) {
        Swal.fire({
          title: "Validations Failed!",
          html: error.response.data.error,
          icon: "error",
          confirmButtonText: "Alright",
        });
      } else if (
        error.response.status == 500 &&
        !this.empty(error.response.data.error)
      ) {
        Swal.fire({
          title: "Error!",
          html: error.response.data.error,
          icon: "error",
          confirmButtonText: "Alright",
        });
      } else if (!this.empty(error.response.data.error)) {
        Swal.fire({
          title: "Error!",
          html: error.response.data.error,
          icon: "error",
          confirmButtonText: "Alright",
        });
        if (error.response.data.error == "Time limit reached") {
          this.signOut();
          this.$router.push("/signIn");
        }
      } else {
        Swal.fire({
          title: "Error!",
          html: "Oops!, unkown Error",
          icon: "error",
          confirmButtonText: "Alright",
        });
      }
    },
    showError(error) {
      Swal.fire({
        title: "Error!",
        html: error,
        icon: "error",
        confirmButtonText: "Alright",
        allowOutsideClick: false,
      });
    },
    showInfo(message) {
      Swal.fire({
        title: "Cool!",
        html: message,
        icon: "info",
        confirmButtonText: "Cool",
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file;
      this.dialogVisible = true;
    },
    showPdfFile(file) {
      this.dialogPdf = file;
      this.dialogPdfVisible = true;
      console.log(this.dialogPdfVisible);
    },
    checkBeforeImageUpload(file) {
      const isFileOK =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/bmp";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isFileOK) {
        this.$message.error("Picture must be JPG format!");
      }
      if (!isLt2M) {
        this.$message.error("Picture size can not exceed 2MB!");
      }
      return isFileOK && isLt2M;
    },
    checkBeforeVideoUpload(file) {
      const isFileOK = file.type === "video/mp4";
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isFileOK) {
        this.$message.error("Vide must be MP4 format!");
      }
      if (!isLt2M) {
        this.$message.error("Vide size can not exceed 10MB!");
      }
      return isFileOK && isLt2M;
    },
    checkBeforePdfUpload(file) {
      const isFileOK = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isFileOK) {
        this.$message.error("File must be PDF format!");
      }
      if (!isLt2M) {
        this.$message.error("File size can not exceed 5MB!");
      }
      return isFileOK && isLt2M;
    },
  },
});
