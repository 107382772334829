<template>
  <v-app>
    <template v-if="!empty(user)">
      <v-app-bar app color="primary" dark class="hidden-sm-and-down">
        EventsMv
        <v-spacer></v-spacer>

        <el-menu
          :default-active="activeIndex"
          background-color="#1876d2"
          text-color="#ffffff"
          active-text-color="#c0c4cc"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-menu-item
            :index="m.id"
            v-for="m in userMenus"
            @click="gotoLink(m)"
            :key="m.id"
          >
            {{ m.text }}
          </el-menu-item>
        </el-menu>

        <v-spacer></v-spacer>

        <v-menu rounded="lg" offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-avatar size="24" class="ml-2" v-bind="attrs" v-on="on">
              <img :src="no_img" />
            </v-avatar>
          </template>
          <v-list dense>
            <v-list-item-group v-model="selectedMenu" color="primary">
              <v-list-item v-for="menu in userMenus" :key="menu.id">
                <v-list-item-content>
                  <v-list-item-title v-text="menu.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="'Log Out'"
                    @click="signOut"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-app-bar>
    </template>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import { Menu, MenuItem } from "element-ui";
Vue.use(Menu);
Vue.use(MenuItem);
export default {
  data() {
    return {
      activeIndex: "0",
    };
  },
  methods: {
    gotoLink(link) {
      this.$router.push(link.link);
    },
  },
  components: {},
  created() {
    console.log(this.$route);
    if (
      this.empty(this.user) &&
      !this.empty(this.$route.params.id) &&
      !this.empty(this.$route.params.param)
    ) {
      this.$router.push("/sign-in");
    }
  },
  name: "App",
};
</script>
<style>
.v-toolbar__extension {
  background: #3c9bf9 !important;
}
</style>
